import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NewVersionDialogComponent } from './components/new-version-dialog/new-version-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './components/shared/table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingContainerComponent } from './components/shared/loading-container/loading-container.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { ErrorMessageDialogComponent } from './components/error-message-dialog/error-message-dialog.component';
import { EnumFormatterPipe } from './pipes/enum-formatter.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FlexModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoundedPercentPipe } from './pipes/rounded-percent.pipe';
import { DateRangePipe } from './pipes/date-range.pipe';
import { NullReplacePipe } from './pipes/null-replace.pipe';
import { StringToColourPipe } from './pipes/string-to-colour.pipe';
import { DatePickerComponent } from './components/shared/date-picker/date-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RealTimeSnackBarComponent } from './components/real-time-snack-bar/real-time-snack-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { EnumSelectorComponent } from './components/shared/enum-selector/enum-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { DateTimePipe } from './pipes/date-time.pipe';
import { StringListPipe } from './pipes/string-list.pipe';
import { DownloadElementAsImageDirective } from './directives/download-element-as-image.directive';
import { FileDragAndDropDirective } from './directives/file-drag-and-drop.directive';
import { EnumSelectorButtonComponent } from './components/shared/enum-selector-button/enum-selector-button.component';
import { FullNamePipe } from './pipes/full-name.pipe';
import { UserValidationPipe } from './pipes/user-validation.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { ChartDownloadButtonComponent } from './components/shared/chart-download-button/chart-download-button.component';
import { MatDateFormats } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { DateTimeFromNowPipe } from './pipes/date-time-from-now.pipe';
import { AddLoadingIconDirective } from './directives/add-loading-icon.directive';
import { AnimatedBigFlowerComponent } from './components/shared/animations/animated-big-flower/animated-big-flower.component';
import { AnimatedLogoComponent } from './components/shared/animations/animated-logo/animated-logo.component';
import { AnimatedAnalystGirlComponent } from './components/shared/animations/animated-analyst-girl/animated-analyst-girl.component';
import { TargetBlankForExternalLinkDirective } from './directives/target-blank-for-external-links.directive';

export const TRACE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
} as MatDateFormats;

@NgModule({
  exports: [
    TableComponent,
    LoadingContainerComponent,
    ClickStopPropagationDirective,
    DownloadElementAsImageDirective,
    FileDragAndDropDirective,
    TargetBlankForExternalLinkDirective,
    EnumFormatterPipe,
    RoundedPercentPipe,
    DateRangePipe,
    NullReplacePipe,
    StringToColourPipe,
    DatePickerComponent,
    EnumSelectorComponent,
    DateTimePipe,
    StringListPipe,
    EnumSelectorButtonComponent,
    FullNamePipe,
    UserValidationPipe,
    InitialsPipe,
    ChartDownloadButtonComponent,
    DateTimeFromNowPipe,
    AddLoadingIconDirective,
    AnimatedBigFlowerComponent,
    AnimatedLogoComponent,
    AnimatedAnalystGirlComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatMenuModule,
    FlexModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    NgOptimizedImage,
    NewVersionDialogComponent,
    TableComponent,
    LoadingContainerComponent,
    ClickStopPropagationDirective,
    ErrorMessageDialogComponent,
    EnumFormatterPipe,
    RoundedPercentPipe,
    DateRangePipe,
    NullReplacePipe,
    StringToColourPipe,
    DatePickerComponent,
    RealTimeSnackBarComponent,
    EnumSelectorComponent,
    DateTimePipe,
    StringListPipe,
    DownloadElementAsImageDirective,
    TargetBlankForExternalLinkDirective,
    FileDragAndDropDirective,
    EnumSelectorButtonComponent,
    FullNamePipe,
    UserValidationPipe,
    InitialsPipe,
    ChartDownloadButtonComponent,
    DateTimeFromNowPipe,
    AddLoadingIconDirective,
    AnimatedBigFlowerComponent,
    AnimatedLogoComponent,
    AnimatedAnalystGirlComponent
  ]
})
export class GlobalModule {
  static forRoot(): ModuleWithProviders<GlobalModule> {
    return {
      ngModule: GlobalModule,
      providers: [provideMomentDateAdapter(TRACE_FORMATS, { useUtc: true })]
    };
  }
}
