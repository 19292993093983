<mat-card [formGroup]="signInForm">
  <mat-card-content>
    <div class="flex flex-col gap-2">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email">
      </mat-form-field>
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password">
        <mat-hint class="forgot-password-hint"><a (click)="forgotPassword()">Forgot your password?</a>
        </mat-hint>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button (click)="signIn()" mat-raised-button color="primary" [disabled]="signInForm.invalid || signInning"
            [appAddLoadingIcon]="signInning">
      Enter
    </button>
  </mat-card-actions>
</mat-card>
