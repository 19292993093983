<mat-card>
  <mat-card-header>
    <mat-card-title>Recover your password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col gap-4 pt-4">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" [formControl]="emailFormControl">
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button (click)="cancel()" mat-flat-button>Cancel</button>
    <button (click)="forgotPassword()" mat-raised-button color="primary"
            [disabled]="emailFormControl.invalid || resettingPassword"
            [appAddLoadingIcon]="resettingPassword">Reset password
    </button>
  </mat-card-actions>
</mat-card>
